export const checkIfUserIsInvited = (email, successCallback, errorCallback) => {
  const queryParam = new URLSearchParams({
    email: email,
  }).toString();
  const url =
    process.env.REACT_APP_IBL_DM_URL +
    '/api/catalog/invitations/platform/check/?' +
    queryParam;
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((resp) => {
      if (resp.status === 404 || resp.status === 200) {
        successCallback(resp.status);
      } else {
        errorCallback();
      }
    })
    .catch((err) => {
      errorCallback();
    });
};

export const checkIfUserIsLicensed = (
  email,
  successCallback,
  errorCallback
) => {
  const queryParam = new URLSearchParams({
    email: email,
  }).toString();
  const url =
    process.env.REACT_APP_IBL_DM_URL +
    '/api/catalog/licenses/user/assignment/check/?' +
    queryParam;
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((resp) => {
      if (resp.status === 404 || resp.status === 200) {
        successCallback(resp.status);
      } else {
        errorCallback();
      }
    })
    .catch((err) => {
      errorCallback();
    });
};

export const checkIfUserCanRegister = (checkFn, email, sucessCb, errorCb) => {
  checkFn(email, sucessCb, errorCb);
};
